require('./bootstrap');

import "bootstrap";

import Vue from 'vue';

Vue.component('entry-form', require('./components/Form.vue').default);

Vue.prototype.$asset = function (path) {
    return window.ASSET_URL + path;
}

import Swal from 'sweetalert2';
Vue.prototype.$swal = Swal;

var app = new Vue({
    el: '#app'
});
