<template>
    <form novalidate @submit.prevent="submit(week)" :class="{ 'was-validated': errors.length > 0 }">
        <div class="row gx-3">
            <div class="col-md">
                <div class="form-floating mb-3">
                    <input type="text" class="form-control" :class="{ 'is-invalid': errors['first_name'] }" id="first_name" placeholder="Jane" v-model="first_name">
                    <label class="form-label" for="first_name">{{ lang.first_name }}</label>
                </div>
            </div>

            <div class="col-md">
                <div class="form-floating mb-3">
                    <input type="text" class="form-control" :class="{ 'is-invalid': errors['last_name'] }" id="last_name" placeholder="Doe" v-model="last_name">
                    <label class="form-label" for="last_name">{{ lang.last_name }}</label>
                </div>
            </div>
        </div>

        <template v-if="country === 'BR'">
            <div class="row gx-3">
                <div class="col-md">
                    <div class="form-floating mb-3">
                        <input type="email" class="form-control" :class="{ 'is-invalid': errors['email'] }" id="email" placeholder="you@example.com" v-model="email">
                        <label class="form-label" for="email">{{ lang.email }}</label>
                    </div>
                </div>

                <div class="col-md">
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" :class="{ 'is-invalid': errors['representative'] }" id="representative" placeholder="00000000" v-model="representative">
                        <label class="form-label" for="representative">{{ lang.representative }}</label>
                    </div>
                </div>
            </div>

            <div class="row gx-3">
                <div class="col-md">
                    <div class="form-floating mb-3">
                        <select class="form-select" :class="{ 'is-invalid': errors['level'] }" id="level" v-model="level">
                            <option value="" disabled selected>{{ lang.select }}</option>
                            <option :value="lang.level_option1">{{ lang.level_option1 }}</option>
                            <option :value="lang.level_option2">{{ lang.level_option2 }}</option>
                            <option :value="lang.level_option3">{{ lang.level_option3 }}</option>
                            <option :value="lang.level_option4">{{ lang.level_option4 }}</option>
                        </select>
                        <label class="form-label" for="level">{{ lang.level }}</label>
                    </div>
                </div>

                <div class="col-md">
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" :class="{ 'is-invalid': errors['distributor'] }" id="distributor" placeholder="00000000" v-model="distributor">
                        <label class="form-label" for="distributor">{{ lang.distributor }}</label>
                    </div>
                </div>
            </div>
        </template>
        <template v-else-if="country === 'AT'">
            <div class="row gx-3">
                <div class="col-md">
                    <div class="form-floating mb-3">
                        <input type="email" class="form-control" :class="{ 'is-invalid': errors['email'] }" id="email" placeholder="you@example.com" v-model="email">
                        <label class="form-label" for="email">{{ lang.email }}</label>
                    </div>
                </div>
            </div>

            <div class="row gx-3">
                <div class="col-md">
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" :class="{ 'is-invalid': errors['representative'] }" id="representative" placeholder="00000000" v-model="representative">
                        <label class="form-label" for="representative">{{ lang.representative }}</label>
                    </div>
                </div>

                <div class="col-md">
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" :class="{ 'is-invalid': errors['zone'] }" id="zone" placeholder="00000000" v-model="zone">
                        <label class="form-label" for="zone">{{ lang.zone }}</label>
                    </div>
                </div>
            </div>

            <div class="row gx-3">
                <div class="col-md">
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" :class="{ 'is-invalid': errors['facebook_page'] }" id="facebook_page" placeholder="https://facebook.com/profile.php?id=0000000000" v-model="facebook_page">
                        <label class="form-label" for="facebook_page">{{ lang.facebook_page }}</label>
                    </div>
                </div>
            </div>

            <div class="row gx-3" v-if="week === 2">
                <div class="col-md">
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" :class="{ 'is-invalid': errors['instagram_page'] }" id="instagram_page" placeholder="https://instagram.com/user_name/" v-model="instagram_page">
                        <label class="form-label" for="instagram_page">{{ lang.instagram_page }}</label>
                    </div>
                </div>
            </div>
        </template>

        <div class="form-check mb-3" :class="{ 'is-invalid': errors['rules'] }">
            <input class="form-check-input" type="checkbox" :class="{ 'is-invalid': errors['rules'] }" :value="true" id="rules_checkbox" v-model="rules">
            <label class="form-check-label" for="rules_checkbox" v-html="lang.rules"></label>
        </div>

        <div class="text-center mt-5">
            <button type="submit" class="btn btn-primary btn-lg" :disabled="submitting">
                <span class="spinner-border spinner-border-sm" v-if="submitting"></span>
                <strong v-if="!submitting">{{ lang.submit }}</strong>
            </button>
        </div>
    </form>
</template>

<script>
export default {
    props: ['country', 'week', 'lang'],
    data: function() {
        return {
            first_name: null,
            last_name: null,
            email: null,
            representative: null,
            level: '',
            distributor: null,
            zone: null,
            facebook_page: null,
            instagram_page: null,
            rules: null,

            errors: [],
            submitting: false,
        }
    },
    computed: {

    },
    methods: {
        submit(week) {
            this.errors = [];
            this.submitting = true;

            axios.post('/submit', {
                first_name: this.first_name,
                last_name: this.last_name,
                email: this.email,
                representative: this.representative,
                level: this.level,
                distributor: this.distributor,
                zone: this.zone,
                facebook_page: this.facebook_page,
                instagram_page: this.instagram_page,
                rules: this.rules ? true : null,
                week: week,
            }).then(function(response) {
                window.location.href = '/thanks';
            }.bind(this)).catch(function(error) {
                this.submitting = false;

                if(error.response.status === 422) {
                    // validation error; handle error.response.data
                    this.errors = error.response.data.errors;

                    var errors = '<p>' + this.lang.errors.header + '</p>';

                    errors += '<ul style="text-align: left;">';

                    for(var field in error.response.data.errors) {
                        errors += '<li>' + error.response.data.errors[field][0] + '</li>';
                    }

                    errors += '</ul>';

                    this.$swal.fire({
                        title: this.lang.errors.whoops,
                        html: errors,
                        icon: 'error',
                    });
                } else if (error.response.status === 409) {
                    // User has already entered this week
                    this.$swal.fire(this.lang.errors.whoops, this.lang.errors.already_entered, 'error');
                } else {
                    this.$swal.fire(this.lang.errors.whoops, this.lang.errors.generic_error, 'error');
                }
            }.bind(this));
        },
    },
}
</script>
